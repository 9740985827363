import { createApp } from "vue";
import "./style.css";
import App from "./App.vue";
import router from "./router";
import { env } from "./utils";
import { registerEventListeners } from "./utils/eventListeners";
import { registerSentry } from "./utils/sentry";

const app = createApp(App);
if (env("VITE_ENVIRONMENT") === "production") registerSentry(app);
app.use(router);
app.mount("#app");
app.provide("providerName", "StudentPulse");

registerEventListeners();

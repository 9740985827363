<template>
  <div class="flex h-svh flex-col items-center justify-between overflow-hidden">
    <BaseHeader class="mb-5">
      <template #leftIcon>
        <Transition name="fade-opacity">
          <BaseSvg v-if="currentStep !== 3" name="caret-left-icon" @click="currentStep--" class="w-7 cursor-pointer" />
        </Transition>
      </template>

      <slot>
        <BaseProgressSteps :totalSteps="5" :transitionDuration="800" :currentStep="currentStep" class="mx-2" />
      </slot>

      <template #rightIcon>
        <div class="text-sm text-interface-250">{{ currentStep }}/5</div>
      </template>
    </BaseHeader>

    <Transition name="fade-opacity" mode="out-in" :duration="200">
      <div class="z-20 w-full px-6 pb-4" :key="currentStep">
        <p class="text-balance font-serif text-3.5xl leading-tighter">
          {{ titleMap[currentStep].title }}
        </p>
        <p v-if="titleMap[currentStep].description" class="mt-6 leading-tight text-interface-250">
          {{ titleMap[currentStep].description }}
        </p>
      </div>
    </Transition>

    <Transition name="fade-opacity" mode="out-in" :duration="200">
      <div class="flex h-full w-full flex-col justify-between overflow-scroll" :key="currentStep">
        <div v-if="currentStep === 3" class="h-full">
          <div
            v-for="(goal, index) in personalGoals"
            :key="index"
            class="flex items-center border-t border-interface-750 px-6 py-5 last:border-b">
            <input
              type="checkbox"
              :id="'checkbox-' + index"
              value=""
              class="peer hidden"
              v-model="selectedGoals[index]" />
            <label :for="'checkbox-' + index" class="flex w-full items-center gap-4 leading-tighter text-interface-0">
              <div
                class="h-6 w-6 shrink-0 rounded-full border"
                :class="
                  selectedGoals[index]
                    ? 'relative border-ios-blue before:absolute before:left-1/2 before:top-1/2 before:block before:h-4 before:w-4 before:-translate-x-1/2 before:-translate-y-1/2  before:rounded-full before:bg-ios-blue'
                    : 'border-interface-350'
                "></div>
              {{ goal }}</label
            >
          </div>
        </div>
        <div v-else-if="currentStep === 4" class="h-full">
          <div class="mt-6 flex flex-col gap-5 px-6 pb-12">
            <div v-for="benefit in personalBenefits" class="flex items-center gap-3">
              <BaseSvg :name="benefit.icon" class="w-8 shrink-0 text-ios-blue" />
              <p>{{ benefit.name }}</p>
            </div>
          </div>
        </div>
        <div v-else-if="currentStep === 5" class="flex h-full items-end justify-center overflow-hidden">
          <div
            v-if="pushNotificationStatus === 'default'"
            class="absolute left-1/2 top-1/2 z-10 flex -translate-x-1/2 -translate-y-1/2 flex-col gap-6">
            <!-- width and height specific to push notifications popup -->
            <div
              class="flex h-[222px] w-[294px] items-center justify-center rounded-2xl border border-ios-blue bg-interface-750">
              <button @click="enableNotifications()" class="rounded bg-interface-350 px-3 py-1">
                {{ uiTranslations.reloadApp }}
              </button>
            </div>
          </div>
          <Transition name="modal-slideup">
            <div
              v-if="pushNotificationStatus === 'denied'"
              class="fixed left-0 top-0 z-50 h-full w-full bg-interface-750/50 backdrop-blur-sm">
              <div
                class="absolute bottom-viewportBottom left-0 flex w-full flex-col items-center justify-center rounded-t-5xl bg-slate-300 px-8 text-black">
                <h2 class="w-full px-5 pt-8 text-center text-3xl font-semibold leading-tight text-interface-750">
                  {{ uiTranslations.enableNotificationsTitle }}
                </h2>
                <div class="pt-4 text-center leading-tight text-interface-500">
                  {{ uiTranslations.enableNotificationsDescription }}
                </div>
                <div class="w-full py-5">
                  <div v-if="getUserAgentSpecs().currentDevice === 'iphone'" class="ml-5 list-decimal">
                    <li v-for="(step, index) in notificationStepsIphone" :key="index">{{ step }}</li>
                  </div>
                  <div v-else class="ml-5 list-decimal">
                    <li v-for="(step, index) in notificationStepsAndroid" :key="index">{{ step }}</li>
                  </div>
                  <button
                    class="my-5 w-full rounded-lg bg-interface-1000 p-3 text-white"
                    @click="enableNotifications()">
                    {{ uiTranslations.done }}
                  </button>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </Transition>

    <div class="mt-8 w-full px-5">
      <button
        v-if="currentStep < 5"
        @click="nextStep()"
        class="w-full rounded-lg bg-interface-0 py-3 text-center font-bold text-interface-750 transition-colors"
        :class="{
          'pointer-events-none bg-interface-250 text-opacity-20':
            currentStep === 3 && selectedGoals.every((goal) => goal === false),
        }">
        {{ uiTranslations.continue }}
      </button>
      <RouterLink v-else :to="pushNotificationStatus === 'granted' ? '/home' : ''">
        <div
          class="w-full rounded-lg bg-blue-600 py-3 text-center font-bold text-white transition-colors"
          :class="{
            'pointer-events-none !bg-interface-0 text-black/20': pushNotificationStatus !== 'granted',
          }">
          {{ uiTranslations.completeCta }}
        </div>
      </RouterLink>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";

import { uiTranslations } from "@/helpers/languageHelpers.ts";
import BaseHeader from "@/components/BaseHeader.vue";
import BaseSvg from "@/components/BaseSvg.vue";
import BaseProgressSteps from "@/components/BaseProgressSteps.vue";
import { checkNotificationPermission, promptPushNotificationPermission } from "@/helpers/swHelper";
import { userStore } from "@/stores/userStore";
import axiosHelper from "@/helpers/axiosHelper";
import { getUserAgentSpecs } from "@/helpers/deviceHelpers";

const currentStep = ref(3);
const pushNotificationStatus = ref(checkNotificationPermission());

const titleMap: Record<number, { title: string; description: string }> = {
  3: { title: uiTranslations.value.selectGoalsTitle, description: "" },
  4: { title: uiTranslations.value.weWillHelpYouTitle, description: "" },
  5: { title: uiTranslations.value.stayConnectedTitle, description: uiTranslations.value.stayConnectedDescription },
};

const personalGoals = ref([
  uiTranslations.value.personalGoal1,
  uiTranslations.value.personalGoal2,
  uiTranslations.value.personalGoal3,
  uiTranslations.value.personalGoal4,
  uiTranslations.value.personalGoal5,
  uiTranslations.value.personalGoal6,
]);

const personalBenefits = ref([
  { name: uiTranslations.value.personalBenefit1, icon: "person-simple-run-icon" },
  { name: uiTranslations.value.personalBenefit2, icon: "sliders-horizontal-icon" },
  { name: uiTranslations.value.personalBenefit3, icon: "hand-fist-icon" },
  { name: uiTranslations.value.personalBenefit4, icon: "trend-up-icon" },
  { name: uiTranslations.value.personalBenefit5, icon: "detective-icon" },
]);

const notificationStepsIphone = ref([
  uiTranslations.value.openSettings,
  uiTranslations.value.tapNotifications,
  uiTranslations.value.selectStudentPulseAppIphone,
  uiTranslations.value.turnOnAllowNotifications,
]);

const notificationStepsAndroid = ref([
  uiTranslations.value.openSettings,
  uiTranslations.value.tapNotifications,
  uiTranslations.value.tapAppSettings,
  uiTranslations.value.selectStudentPulseAppAndroid,
  uiTranslations.value.turnOnAllowNotifications,
]);

const selectedGoals = ref([false, false, false, false, false, false]);

function nextStep() {
  currentStep.value++;
  if (currentStep.value === 5) enableNotifications();
}

async function enableNotifications() {
  try {
    const status = await promptPushNotificationPermission();
    pushNotificationStatus.value = status;

    if (status === "granted") {
      const updatedUser = { ...userStore.user, magicLink: { ...userStore.user.magicLink, isOnboarded: true } };
      userStore.updateUser(updatedUser);
      await axiosHelper.put("/participants/me", { "magicLink.isOnboarded": true });
    } else console.log("push notification not granted");
  } catch (error) {
    console.error("error", error);
  }
}
</script>

<template>
  <div class="w-screen h-screen overflow-clip">
    <!-- SKIP MODAL AND BACKDROP -->
    <section>
      <Transition name="fade-opacity" :duration="100">
        <div
          v-if="isSkipModalShown"
          class="fixed inset-0 z-40 backdrop-blur-2xl"
          @click="isSkipModalShown = false"></div>
      </Transition>
      <Transition name="slide-up">
        <div v-if="isSkipModalShown" class="absolute bottom-0 left-0 right-0 z-50">
          <div class="w-full px-2.5 pb-5 text-center text-xl">
            <div
              class="flex flex-col overflow-clip rounded-xl bg-opacity-70 *:border-b"
              :class="
                !userStore?.user?.preferences.checkinLightModePreference
                  ? 'bg-interface-750 text-interface-0 *:border-interface-500'
                  : 'bg-interface-25 text-interface-1000 *:border-interface-150'
              ">
              <div class="px-4 py-3 text-sm text-interface-350">
                {{ uiTranslations.whyAreYouSkipping }}
              </div>
              <div
                v-for="reason in skipReasons"
                :key="reason.name"
                @click="handleSkipInput(reason.name)"
                class="px-4 py-4 last:border-none">
                {{ reason.translation }}
              </div>
            </div>
            <div
              @click="handleSkipInput('LEAVE_COMMENT')"
              class="px-4 py-4 mt-2 font-semibold rounded-xl bg-opacity-80"
              :class="
                !userStore?.user?.preferences.checkinLightModePreference
                  ? 'bg-interface-750 text-interface-0'
                  : 'bg-interface-0 text-interface-1000'
              ">
              {{ uiTranslations.leaveCommentWithoutScore }}
            </div>
            <div
              @click="isSkipModalShown = false"
              class="px-4 py-4 mt-2 font-semibold rounded-xl bg-opacity-80"
              :class="
                !userStore?.user?.preferences.checkinLightModePreference
                  ? 'bg-interface-750 text-interface-0'
                  : 'bg-interface-0 text-interface-1000'
              ">
              {{ uiTranslations.close }}
            </div>
          </div>
        </div>
      </Transition>
    </section>

    <div class="flex flex-col w-full h-full">
      <BaseHeader class="mb-5">
        <template #leftIcon>
          <Transition name="fade-opacity">
            <BaseSvg
              v-if="currentStepIndex !== 0 || isCommentShown"
              name="caret-left-icon"
              @click="handleBackButtonClick()"
              class="cursor-pointer w-7" />
          </Transition>
        </template>

        <template #rightIcon>
          <RouterLink
            v-if="isStandalone()"
            :to="getExitButtonRouterLinkTarget()"
            class="p-1 transition-opacity duration-500"
            :class="isTitleLoading ? 'cursor-not-allowed opacity-20' : ''">
            <div class="relative">
              <BaseSvg name="x-icon" class="w-7" />
            </div>
          </RouterLink>
        </template>
      </BaseHeader>

      <CheckinFlowSteps
        @showSkipModal="isSkipModalShown = true"
        :totalSteps="currentAndTransferedCheckinQuestions.all.length"
        :isSkipButtonShown="
          currentQuestionVocabulary!.type === 'COMMENT' ||
          (currentQuestionVocabulary!.type === 'RATING' && !isCommentShown)
        "
        :isTitleLoading
        :currentStepIndex
        :titleLoadingTimeInMs="getTitleLoadingTimeInMilliseconds(currentQuestionVocabulary!.phrasing)" />
      <div class="relative h-full">
        <TransitionGroup name="remove-and-fade">
          <CheckinFlowRating
            v-show="currentQuestionVocabulary!.type === 'RATING' && !isCommentShown"
            @resetTitleLoading="isTitleLoading = false"
            @handleRatingInput="handleRatingInput"
            :key="isCommentShown.toString()"
            :currentStepIndex
            :sliderColor="getSliderColor('#1B508F')"
            :isTitleLoading
            :currentQuestionVocabulary
            :oldAnswer
            :titleLoadingTimeInMs="getTitleLoadingTimeInMilliseconds(currentQuestionVocabulary!.phrasing)" />
          <CheckinFlowComment
            v-show="isCommentShown"
            :key="isCommentShown.toString()"
            @handleCommentInput="handleCommentInput"
            :hasFocus="isCommentShown"
            :commentTitle
            :oldAnswer />
        </TransitionGroup>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from "vue";
import { vocabularyStore } from "@/stores/vocabularyStore";
import { userStore } from "@/stores/userStore";
import { useRouter } from "vue-router";
import BaseHeader from "@/components/BaseHeader.vue";
import CheckinFlowRating from "@/components/CheckinFlowRating.vue";
import CheckinFlowComment from "@/components/CheckinFlowComment.vue";
import CheckinFlowSteps from "@/components/CheckinFlowSteps.vue";
import { uiTranslations } from "@/helpers/languageHelpers.ts";
import { currentAndTransferedCheckinQuestions } from "@/helpers/checkinHelpers";
import BaseSvg from "@/components/BaseSvg.vue";
import { VocabularyQuestion } from "@/types";
import { isStandalone } from "@/helpers/deviceHelpers";
import { driverStylesMap } from "@/helpers/actionHelpers.ts";

const router = useRouter();

const currentStepIndex = computed(() => userStore.user.currentStepIndex);

const commentTitle = ref("");
const commentRole = ref<"ELABORATION" | "SKIP" | undefined>();

const isTitleLoading = ref(true);
const isCommentShown = ref(false);
const isSkipModalShown = ref(false);

const skipReasons = [
  { name: "NOT_APPLY", translation: uiTranslations.value.skipNotApply },
  { name: "NOT_UNDERSTAND", translation: uiTranslations.value.skipNotUnderstand },
  { name: "NO_OPINION", translation: uiTranslations.value.skipNoOpinion },
  { name: "OTHER_REASON", translation: uiTranslations.value.skipOtherReason },
];

function handleBackButtonClick() {
  if (currentStepIndex.value === 0 && !isCommentShown.value) {
    return;
  } else {
    isTitleLoading.value = false;
    isCommentShown.value
      ? (isCommentShown.value = false)
      : userStore.setCurrentStepIndex(userStore.user.currentStepIndex - 1);
  }
}

function getSliderColor(fallbackColor: string) {
  if (!currentQuestion.value || !currentQuestion.value.driverId) return fallbackColor;
  return driverStylesMap[currentQuestion.value.driverId]?.colors?.secondary ?? fallbackColor;
}

function getExitButtonRouterLinkTarget() {
  if (!isStandalone()) return "";
  if (isTitleLoading.value) {
    return "";
  } else {
    return "/home";
  }
}

function getTriggeredComment(rating: number) {
  if (!currentQuestionVocabulary.value) return null;
  return currentQuestionVocabulary.value.commentTriggers.find((item: any) => item.triggers.includes(rating));
}

const currentQuestion = computed(() => {
  return currentAndTransferedCheckinQuestions.value.all[currentStepIndex.value];
});

const oldAnswerIndex = computed(() => {
  return userStore?.user?.answers.findIndex(
    (answer) => answer.questionId === currentQuestion.value._id && answer.checkinId === currentQuestion.value.checkinId,
  );
});

const oldAnswer = computed(() => {
  return userStore?.user?.answers[oldAnswerIndex.value];
});

const currentQuestionVocabulary = computed(() => {
  return vocabularyStore?.vocabulary?.questions.find(
    (question: VocabularyQuestion) => question._id === currentQuestion.value._id,
  ) as VocabularyQuestion;
});

function handleRatingInput(input: number) {
  if (isTitleLoading.value) {
    return;
  }
  updateOrCreateAnswer({ newRating: input });
  if (getTriggeredComment(input)) {
    commentRole.value = "ELABORATION";
    commentTitle.value = getCommentTitle(input) || "";
    isCommentShown.value = true;
  } else {
    const questions = currentAndTransferedCheckinQuestions.value;
    if (currentStepIndex.value + 1 === questions.all.length) handleEndFlow();
    userStore.setCurrentStepIndex(userStore.user.currentStepIndex + 1);
    isTitleLoading.value = !oldAnswer.value;
  }
}

function getCommentTitle(rating: number) {
  const triggeredComment = getTriggeredComment(rating);
  if (!triggeredComment || !triggeredComment.elaboration) return;
  if (commentRole.value === "ELABORATION") return triggeredComment.elaboration;
  if (commentRole.value === "SKIP") return uiTranslations.value.leaveCommentWithoutScore;
  if (currentQuestionVocabulary.value?.phrasing) return currentQuestionVocabulary.value?.phrasing;
  return null;
}

function handleCommentInput(input: string) {
  updateOrCreateAnswer({ newComment: input });
  isCommentShown.value = false;
  commentRole.value = undefined;
  const questions = currentAndTransferedCheckinQuestions.value;
  if (currentStepIndex.value + 1 === questions.all.length) handleEndFlow();
  userStore.setCurrentStepIndex(userStore.user.currentStepIndex + 1);
  isTitleLoading.value = !oldAnswer.value;
}

function handleEndFlow() {
  userStore.updateAnswers();
  router.push({ name: "Home" });
}

function handleSkipInput(input: string) {
  isSkipModalShown.value = false;
  updateOrCreateAnswer({ newSkipReason: input });

  if (input === "LEAVE_COMMENT") {
    commentRole.value = "SKIP";
    isCommentShown.value = true;
  } else {
    const questions = currentAndTransferedCheckinQuestions.value;
    if (currentStepIndex.value + 1 === questions.all.length) handleEndFlow();
    userStore.setCurrentStepIndex(userStore.user.currentStepIndex + 1);
    isTitleLoading.value = !oldAnswer.value;
    isCommentShown.value = false;
  }
}
function updateOrCreateAnswer({
  newRating,
  newComment,
  newSkipReason,
}: {
  newRating?: number;
  newComment?: string;
  newSkipReason?: string;
}) {
  if (!userStore.user?.answers) return;
  if (!oldAnswer.value) {
    const newAnswer = {
      questionId: currentQuestion!.value["_id"],
      checkinId: currentQuestion!.value["checkinId"]!,
      createdAt: new Date().toISOString(),
      rating: newRating !== undefined ? newRating : undefined,
      skipReason: newSkipReason !== undefined ? newSkipReason : "",
      comment: newComment !== undefined ? newComment : "",
    };
    userStore.updateUser({ ...userStore.user, answers: [...userStore.user.answers, newAnswer] });
  } else {
    const updatedAnswer = {
      questionId: oldAnswer.value["questionId"],
      checkinId: oldAnswer.value["checkinId"],
      createdAt: new Date().toISOString(),
      rating: newRating !== undefined ? newRating : oldAnswer.value.rating,
      skipReason: newSkipReason !== undefined ? newSkipReason : oldAnswer.value.skipReason,
      comment: newComment !== undefined ? newComment : oldAnswer.value.comment,
    };

    let answersArray = userStore?.user?.answers;
    answersArray[oldAnswerIndex.value] = updatedAnswer;

    userStore.updateUser({ ...userStore.user, answers: answersArray });
  }
}

function getTitleLoadingTimeInMilliseconds(phrasing: string) {
  const currentQuestionLengthInCharacters = phrasing.length;

  if (!currentQuestionLengthInCharacters) {
    return 1500;
  } else {
    return currentQuestionLengthInCharacters * 45;
  }
}

onMounted(() => {
  const questions = currentAndTransferedCheckinQuestions.value;
  if (questions.answered < questions.all) {
    userStore.setCurrentStepIndex(questions.answered.length);
  }
});
</script>

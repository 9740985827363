import * as Sentry from "@sentry/vue";
import { metrics } from "@sentry/vue";
import { App } from "vue";

type EVENT_NAMES = "AUTH_MAGIC_LINK";

export function registerSentry(app: App) {
  Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [Sentry.replayIntegration(), Sentry.captureConsoleIntegration()],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

export function registerEvent(eventName: EVENT_NAMES, data?: Record<string, string | number>) {
  return metrics.increment(eventName, 1, {
    tags: data,
  });
}

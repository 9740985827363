<template>
  <div v-if="readyToRender">
    <DownloadDesktopView v-if="getUserAgentSpecs().currentDevice === 'desktop'"></DownloadDesktopView>
    <DownloadPhoneView v-else></DownloadPhoneView>
  </div>
</template>

<script setup lang="ts">
import DownloadPhoneView from "@/views/DownloadPhoneView.vue";
import DownloadDesktopView from "@/views/DownloadDesktopView.vue";
import { onBeforeMount, ref } from "vue";
import { userStore } from "@/stores/userStore";
import { useRoute, useRouter } from "vue-router";
import axiosHelper from "@/helpers/axiosHelper";
import { getUserAgentSpecs, isStandalone } from "@/helpers/deviceHelpers";

const route = useRoute();
const router = useRouter();

const readyToRender = ref(false);
function isFirstTimeOpen() {
  return Boolean(userStore?.user?._id) === false;
}

async function authenticate() {
  const response = await axiosHelper.post("/auth/validate", {
    hash: route.params.uid?.toString(),
  });
  if (response.data?.success) {
    userStore.updateUser(response.data.data);
    router.push({ name: "Home" });
  } else {
    // TODO: handle this better
    alert("Authentication failed. Please try again.");
  }
}
onBeforeMount(async () => {
  if (!isStandalone()) {
    readyToRender.value = true;
    console.log("not standalone!", isStandalone());
    return;
  }
  console.log("standalone");
  if (isFirstTimeOpen()) {
    console.log("first time open");
    await authenticate();
    console.log("authenticated", userStore.user);
  } else {
    console.log("not first time open");
    router.push({ name: "Home" });
    console.log("redirected to home");
  }
});
</script>

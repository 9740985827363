<template>
  <div class="flex flex-col items-center justify-center w-full h-full">
    <div class="animate-pulse">{{ uiTranslations.authenticating }}</div>
  </div>
</template>
<script lang="ts" setup>
import { onMounted } from "vue";
import axiosHelper from "@/helpers/axiosHelper";
import { useRoute, useRouter } from "vue-router";
import { userStore } from "@/stores/userStore";
import { surveyStore } from "@/stores/surveyStore";
import { vocabularyStore } from "@/stores/vocabularyStore";
import { getAvailableUserLanguage } from "@/helpers/languageHelpers.ts";
import { uiTranslations } from "@/helpers/languageHelpers.ts";

const router = useRouter();
const route = useRoute();

async function authenticate() {
  const response = await axiosHelper.post("/auth/validate");
  if (response.data?.success) {
    return response.data.data;
  }
  return null;
}

onMounted(async () => {
  try {
    const user = await authenticate();
    await Promise.all([
      userStore.updateUser(user),
      vocabularyStore.fetchAndSetVocabulary(getAvailableUserLanguage(), user.segmentRevision),
      surveyStore.fetchAndSetSurveys(user.segmentRevision),
    ]);
    router.push({ name: "Start check-in" });
  } catch (err) {
    router.push("/onboarding/" + route.query.organization);
  }
});
</script>

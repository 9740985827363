<template>
  <RouterLink :to="`/actions/${actionItem._id}`">
    <div
      v-if="props.size === 'large' && !isMarkedNotRelevant"
      class="overflow-clip rounded-lg"
      :style="{
        background: `${actionBgGradient}`,
      }">
      <div class="flex w-full flex-col justify-between gap-5 bg-interface-750 bg-opacity-70 px-4 pb-6 pt-5">
        <section v-if="actionItem.tags.length" class="flex items-center justify-end gap-1 text-xs">
          <div v-for="tag in actionItem.tags" class="rounded-l-full rounded-r-full bg-interface-25/15 px-2 py-0.5">
            {{ tag }}
          </div>
        </section>
        <section class="max-h- grid grid-cols-[1fr_auto] items-end justify-between gap-6">
          <div class="flex flex-grow flex-col gap-2">
            <div class="flex gap-2 text-xs font-medium">
              <span class="min-w-10 truncate" :style="{ color: driverColors.secondary }"
                >{{ driverStylesMap[driverId].name }}
              </span>
              <span>•</span>
              <!-- TODO: CREATE MAP FOR CATEGORY WHERE ENUM IS MAPPED TO LANGUAGE SPECIFIC NAME -->
              <span class="min-w-10 truncate capitalize">{{ category.toLowerCase() }} </span>
            </div>
            <div class="line-clamp-2 font-serif text-2xl leading-tighter">{{ title }}</div>
          </div>
          <BaseSvg
            :name="driverStylesMap[driverId].icon"
            class="h-[70%] max-h-20"
            :style="{ color: driverColors.secondary }" />
        </section>
      </div>
    </div>

    <div v-else-if="props.size === 'small'" class="flex h-14 w-full items-center justify-between gap-3">
      <div class="h-full w-20 shrink-0 rounded p-4" :style="{ backgroundColor: driverColors.dark }">
        <BaseSvg
          :name="driverStylesMap[driverId].icon"
          class="h-full w-full"
          :style="{ color: driverColors.secondary }" />
      </div>
      <div class="flex w-full flex-grow flex-col gap-0.5">
        <div class="line-clamp-2 text-sm font-medium leading-tighter">{{ title }}</div>
        <div class="flex w-full items-center gap-1.5 overflow-hidden text-xs">
          <div class="line-clamp-1 capitalize text-interface-250">
            {{ category.toLowerCase() }}
          </div>
          <Transition name="fade-opacity" :duration="100">
            <div v-if="isMarkedNotRelevant" class="flex shrink-0 gap-1.5">
              <span>·</span>
              <span class="line-clamp-1 shrink-0 text-rust">{{ uiTranslations.notRelevantLabel }} </span>
            </div>
          </Transition>
        </div>
      </div>
      <div class="flex h-full w-12 shrink-0 items-center justify-center">
        <BaseSvg name="caret-right-icon" class="w-6" />
      </div>
    </div>
  </RouterLink>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { uiTranslations } from "@/helpers/languageHelpers.ts";
import { userStore } from "@/stores/userStore";
import { driverStylesMap } from "@/helpers/actionHelpers.ts";
import BaseSvg from "@/components/BaseSvg.vue";
import { ActionItem } from "@/types";

const props = defineProps<{
  actionItem: ActionItem;
  size: "small" | "large";
}>();

const { category, driverId, title } = props.actionItem;

const driverColors = driverStylesMap[driverId].colors;
const actionBgGradient = `linear-gradient(to right, ${driverColors.secondary}, ${driverColors.dark})`;
const isMarkedNotRelevant = computed(() => {
  if (!userStore?.user?.notRelevantActions) return false;
  return userStore?.user?.notRelevantActions?.includes(props.actionItem._id);
});
</script>

<template>
  <div class="flex flex-col">
    <BaseHeader class="mb-2.5">
      <template #leftIcon>
        <RouterLink to="/home" class="min-h-max p-1">
          <div class="relative">
            <BaseSvg name="caret-left-icon" class="w-7" />
          </div>
        </RouterLink>
      </template>
    </BaseHeader>

    <main
      class="flex h-full w-full flex-col items-stretch justify-start gap-5 overflow-x-hidden overflow-y-scroll px-5 pb-5">
      <div class="h-44 rounded-lg p-6" :style="{ backgroundColor: driverColors.dark }">
        <BaseSvg
          :name="driverStylesMap[actionItem!.driverId].icon"
          class="h-full w-full"
          :style="{ color: driverColors.secondary }" />
      </div>

      <section>
        <div class="flex flex-col gap-2">
          <div class="flex gap-2 text-sm font-medium">
            <span class="min-w-10 truncate" :style="{ color: driverColors.secondary }"
              >{{ driverStylesMap[actionItem!.driverId].name }}
            </span>
            <span>•</span>
            <!-- TODO: CREATE MAP FOR CATEGORY WHERE ENUM IS MAPPED TO LANGUAGE SPECIFIC NAME -->
            <span class="min-w-10 truncate capitalize">{{ actionItem!.category.toLowerCase() }} </span>
          </div>
          <div class="line-clamp-2 font-serif text-3xl leading-tighter tracking-tighter">
            {{ actionItem!.title }}
          </div>
          <section v-if="actionItem!.tags.length" class="flex items-center justify-start gap-1 text-xs">
            <div v-for="tag in actionItem!.tags" class="rounded-l-full rounded-r-full bg-interface-25/15 px-3 py-1">
              {{ tag }}
            </div>
          </section>
        </div>
      </section>

      <p>
        {{ actionItem!.phrasing }}
      </p>
    </main>

    <section class="mt-auto flex flex-col gap-1 px-5 pt-5">
      <div
        v-if="actionItem!.cta_url"
        @click="navigateToUrl(actionItem!.cta_url)"
        class="w-full rounded-lg bg-interface-0 py-3 text-center font-bold text-interface-1000">
        {{ actionItem!.cta_title }}
      </div>

      <div
        @click="handleRelevantToggle(route.params.id.toString())"
        class="w-full rounded-lg py-3 text-center font-bold">
        <Transition name="fade-opacity" mode="out-in" :duration="100">
          <span v-if="userStore?.user?.notRelevantActions?.includes(actionItem!._id)">{{
            uiTranslations.markRelevantForMe
          }}</span>
          <span v-else>
            {{ uiTranslations.notForMe }}
          </span>
        </Transition>
      </div>
    </section>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useRoute } from "vue-router";
import BaseHeader from "@/components/BaseHeader.vue";
import BaseSvg from "@/components/BaseSvg.vue";
import { driverStylesMap, handleRelevantToggle } from "@/helpers/actionHelpers.ts";
import { userStore } from "@/stores/userStore";
import { vocabularyStore } from "@/stores/vocabularyStore";
import { uiTranslations } from "@/helpers/languageHelpers";
import { ActionItem } from "@/types";

const route = useRoute();
const actionItem = computed(() => {
  return vocabularyStore?.vocabulary?.actions.find((action: ActionItem) => action._id === route.params.id);
});
const driverColors = driverStylesMap[actionItem.value!.driverId].colors;
function navigateToUrl(url: string) {
  if (url) {
    window.open(url, "_blank");
  }
}
</script>

export async function promptPushNotificationPermission() {
  try {
    checkBrowserSupport();
    return await requestNotificationPermission();
  } catch (error) {
    console.error("Service Worker registration failed:", error);
    return "denied";
  }
}

function checkBrowserSupport() {
  if (!("serviceWorker" in navigator)) throw new Error("No support for service worker!");
  if (!("Notification" in window)) throw new Error("No support for notification API");
  if (!("PushManager" in window)) throw new Error("No support for Push API");
}

async function requestNotificationPermission() {
  const permission = await Notification.requestPermission();
  if (permission === "granted") {
    await navigator.serviceWorker.ready;
    navigator.serviceWorker.controller?.postMessage({ type: "CLIENT_CREATE_SUBSCRIPTION" });
  }
  if (permission !== "granted") console.error("permission not granted", permission);
  return permission;
}

export function checkNotificationPermission() {
  checkBrowserSupport();
  return Notification.permission;
}

<template>
  <div v-html="svg" v-if="svg"></div>
</template>

<script lang="ts" setup>
import { computed } from "vue";

const props = defineProps<{
  name: string;
}>();

const modules = import.meta.glob("/src/assets/svg/*.svg", {
  query: "?raw",
  import: "default",
  eager: true,
});

const svg = computed(() => {
  return modules["/src/assets/svg/" + (props.name ?? "") + ".svg"] ?? null;
});
</script>

<template>
  <div class="flex flex-col items-center justify-center w-full h-full">
    <div class="animate-pulse">{{ uiTranslations.authenticating }}</div>
  </div>
</template>

<script lang="ts" setup>
import { onBeforeMount } from "vue";
import axiosHelper from "@/helpers/axiosHelper";
import { useRoute, useRouter } from "vue-router";
import { uiTranslations } from "@/helpers/languageHelpers.ts";
import { isStandalone } from "@/helpers/deviceHelpers";
import { userStore } from "@/stores/userStore";
import { captureException } from "@sentry/vue";
import { registerEvent } from "@/utils/sentry";

const route = useRoute();
const router = useRouter();

async function authenticate() {
  registerEvent("AUTH_MAGIC_LINK", { hash: route.params.hash?.toString() });
  //Already authenticated, onboarded and in standalone
  if (isStandalone() && userStore.user?._id && userStore.user?.magicLink?.isOnboarded) router.push("/home");

  try {
    const response = await axiosHelper.post("/auth/magiclink", { hash: route.params.hash?.toString() });
    if (!response.data?.data || !response.data?.success)
      return captureException(new Error("Magiclink authentication failed"), {
        extra: { response: response.data },
      });
    let responseData = response.data.data;
    userStore.updateUser(responseData);

    //Not a valid magiclink
    if (!responseData?._id) return router.push("/auth/request");

    //first-time in-app
    if (isStandalone() && !responseData?.magicLink?.isOnboarded) return router.push("/onboarding");

    if (isStandalone() && !responseData?.magicLink?.isOnboarded && route.query?.mode === "checkin")
      return router.push("/auth/web");

    //TODO: check middleman attack by validating magiclink used previously
    if (isStandalone() && responseData?.magicLink?.isOnboarded) router.push("/home");

    return router.push({ path: "/uid/" + response.data.data._id });
  } catch (err) {
    if (err) captureException(err);
  }
}

onBeforeMount(async () => {
  await authenticate();
});
</script>

<template>
  <div class="flex h-14 w-full shrink-0 items-center justify-between px-4 py-2">
    <span class="flex h-full min-w-10 items-center justify-center">
      <slot name="leftIcon"></slot>
    </span>
    <h1 v-if="props.headerTitle" class="text-2xl font-medium">{{ props.headerTitle }}</h1>
    <slot v-else></slot>
    <span class="flex h-full min-w-10 items-center justify-center">
      <slot name="rightIcon"></slot>
    </span>
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  headerTitle?: string;
}>();
</script>

import { Router } from "vue-router";

/**
 * Update CSS variable
 * set '--viewportBottom' to visuakViewport.height
 * to fix keyboard height etc. on mobile
 */
export function registerResizeEventListener() {
  window.visualViewport?.addEventListener("resize", () => {
    document.documentElement.style.setProperty(
      "--viewportBottom",
      `${window.innerHeight - (window.visualViewport?.height ?? 0)}px`,
    );
  });
}

/**
 * Check if an object is empty.
 * @param obj - The object to check.
 * @returns `true` if the object is empty, `false` otherwise.
 */
export function isEmpty(obj: object) {
  return Object.keys(obj).length === 0;
}

/**
 * Get the value of an environment variable.
 */
export function env(key: string, fallback?: string) {
  if (!(key in import.meta.env)) return fallback ?? null;

  const value = import.meta.env[key];

  if (value.toLowerCase() === "true") return true;
  if (value.toLowerCase() === "false") return false;
  if (value.toLowerCase() === "null") return null;

  return value;
}

export function lastRouteName(router: Router) {
  const backUrl = router.options.history.state.back;
  const lastRoute = router.resolve({ path: `${backUrl}` });

  return lastRoute.name;
}

/**
 * Get compareable date as `number`, formatted as `yyyymmdd`
 */
function getFormattedDate(inputDate: Date): number | null {
  if (!(inputDate instanceof Date)) throw new Error('Invalid argument: you must provide a "Date" instance');

  const formattedDateString =
    inputDate.getFullYear() + ("0" + (inputDate.getMonth() + 1)).slice(-2) + ("0" + inputDate.getDate()).slice(-2);

  return Number(formattedDateString);
}

/**
 * Check if targetDate is tomorrow
 */
export function isTomorrow(targetDate: Date) {
  if (!(targetDate instanceof Date)) throw new Error('Invalid argument: you must provide a "Date" instance');

  const today = new Date();
  const tomorrow = new Date(new Date().setDate(today.getDate() + 1)); //  today + 1

  return getFormattedDate(targetDate) === getFormattedDate(tomorrow);
}

/**
 * Get the amount of days between targetDate and today.
 */
export function getDifferenceFromTodayInDays(targetDate: Date): number {
  if (!(targetDate instanceof Date)) throw new Error('Invalid argument: you must provide a "Date" instance');

  const todayDate = new Date();
  const differenceInMilliseconds = targetDate.getTime() - todayDate.getTime();
  const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);

  return differenceInDays;
}

/**
 * Check if two floating point numbers are equal within epsilon
 */
export function compareFloats(num1: number, num2: number) {
  const epsilon = 0.000001;
  const diff = num1 - num2;

  if (Math.abs(diff) < epsilon) return 0; // num1 === num2 (within epsilon)
  if (diff > 0) return 1; // num1 > num2
  return -1; // num1 < num2
}

<template>
  <!-- TODO UPDATE DESIGN -->
  <div class="absolute bottom-0 z-50 mb-4 flex w-full items-center justify-center px-4">
    <div class="mx-auto w-full max-w-lg rounded-md border border-white bg-black p-3">
      <div>{{ uiTranslations.newUpdateAvailable }}</div>
      <div class="mt-4 flex justify-between">
        <div
          class="rounded-md bg-white px-3 py-1 text-black transition-all active:bg-gray-200"
          @click="updateServiceWorker()">
          {{ uiTranslations.reloadApp }}
        </div>
        <div @click="emit('hide')" class="rounded-md px-3 py-1 text-white transition-all active:bg-gray-200">
          {{ uiTranslations.cancel }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useRegisterSW } from "virtual:pwa-register/vue";
import { uiTranslations } from "@/helpers/languageHelpers.ts";
const { updateServiceWorker } = useRegisterSW();
const emit = defineEmits<{
  (e: "hide"): void;
}>();
</script>

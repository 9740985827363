<template>
  <div v-if="isLoading" class="text-center animate-pulse">
    {{ uiTranslations.loading }}
  </div>

  <div v-else class="flex flex-col">
    <BaseHeader>
      <template #rightIcon>
        <RouterLink v-if="isStandalone()" :to="'/settings'" class="p-1">
          <div class="relative">
            <BaseSvg name="gear-six-icon" class="w-7" />
          </div>
        </RouterLink>
      </template>
    </BaseHeader>

    <main
      class="flex flex-col items-center w-full h-full px-5 mt-5 overflow-x-hidden overflow-y-auto"
      :class="{
        'justify-between': !isFinished || (isFinished && !isFinishAnimationActive && triggeredActionItems.length),
      }">
      <h1 class="mx-2.5 mb-5 text-balance text-center font-serif text-3xl font-light leading-8 tracking-tight">
        {{ getHomeViewProperties().title }}
      </h1>

      <div
        v-if="getHomeViewProperties().isProgressCircleShown"
        class="relative flex items-center w-full max-w-md"
        :class="
          isFinishAnimationActive
            ? 'finished-container-lock pointer-events-none'
            : isFinished
              ? 'pointer-events-none h-[100px]'
              : ''
        ">
        <RouterLink :to="getHomeViewProperties().buttonTarget" class="w-full rounded-full aspect-square">
          <div
            class="absolute flex items-center justify-center w-full -translate-x-1/2 -translate-y-1/2 rounded-full pointer-events-none left-1/2 top-1/2 aspect-square">
            <ProgressCircle
              :colors="driverColors"
              :answerCount="currentAndTransferedCheckinQuestions.answered.length"
              :isFillAnimated="!(isFinished && !isFinishAnimationActive)"
              :type="getProgressCircleType()"
              :class="isFinishAnimationActive ? 'finished-circle-lock' : isFinished ? '!w-16' : ''"></ProgressCircle>
          </div>

          <div
            class="relative flex flex-col items-center justify-center w-full -translate-x-1/2 rounded-full left-1/2 top-1/2 aspect-square"
            :class="getUserAgentSpecs().currentBrowser === 'safari' ? '' : '-translate-y-1/2'">
            <div class="relative h-14 w-14">
              <div
                v-if="!isFinished"
                class="absolute inset-0 w-full h-full rounded-full animate-ping-sm bg-interface-25"></div>
              <div class="relative inset-0 flex items-center justify-center w-full h-full rounded-full bg-interface-25">
                <BaseSvg v-if="isFinished" name="checkmark-icon" class="w-8 text-interface-1000" />
                <BaseSvg v-else-if="isStarted" name="play-icon" class="w-8 text-interface-1000" />
                <BaseSvg v-else name="plus-icon" class="w-8 text-interface-1000" />
              </div>
            </div>

            <div v-if="!isFinished" class="-mb-5 mt-5 min-h-5 max-w-[80%] text-center text-sm">
              {{ isStarted ? uiTranslations.resumeCheckinShort : uiTranslations.startCheckinShort }}
            </div>
          </div>
        </RouterLink>
      </div>

      <div v-if="isFinished" class="flex flex-col gap-2 mb-auto">
        <div v-if="isFinishAnimationActive" class="mt-10 mb-2 text-center">
          <h2 class="mb-2 text-2xl font-medium">
            {{ uiTranslations.loading }}
          </h2>
          <div class="text-interface-250">
            {{ uiTranslations.loadingActionItemsDescription }}
          </div>
        </div>

        <div
          v-else-if="!triggeredActionItems.length || areAllTriggeredActionsNotRelevant"
          class="mt-10 mb-2 text-center">
          <h2 class="mb-2 text-2xl font-medium">
            {{ uiTranslations.noActionItemsTitle }}
          </h2>
          <div class="text-interface-250">
            {{ uiTranslations.noActionItemsDescription }}
          </div>
        </div>

        <div v-else class="mt-10 mb-2 text-center">
          <h2 class="mb-2 text-2xl font-medium">
            {{ uiTranslations.actionItemListTitle }}
          </h2>
          <div class="text-interface-250">
            {{ uiTranslations.actionItemListDescription }}
          </div>
        </div>

        <div :class="isFinishAnimationActive ? 'h-0 overflow-hidden' : 'h-auto'">
          <div
            class="flex flex-col transition-all"
            :class="isFinishAnimationActive ? ' gap-20 opacity-0' : ' gap-2 opacity-100'">
            <ActionItemCard
              v-for="actionItem in triggeredActionItems"
              :key="actionItem._id"
              :actionItem="actionItem"
              size="large" />
          </div>
        </div>
      </div>

      <RouterLink
        v-if="getHomeViewProperties().isButtonShown"
        :to="getHomeViewProperties().buttonTarget"
        class="w-full py-3 my-6 font-bold text-center rounded-lg bg-interface-0 text-interface-1000">
        {{ getHomeViewProperties().buttonLabel }}
      </RouterLink>
    </main>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from "vue";
import { useRouter } from "vue-router";

import {
  currentCheckin,
  currentCheckinStatus,
  currentAndTransferedCheckinQuestions,
  getComingSoonTitle,
} from "@/helpers/checkinHelpers.ts";

import { uiTranslations, getAvailableUserLanguage } from "@/helpers/languageHelpers.ts";
import { driverStylesMap, triggeredActionItems, areAllTriggeredActionsNotRelevant } from "@/helpers/actionHelpers.ts";
import { getUserAgentSpecs, isStandalone } from "@/helpers/deviceHelpers";
import { isEmpty, lastRouteName } from "@/utils";
import { userStore } from "@/stores/userStore";
import { surveyStore } from "@/stores/surveyStore";
import { vocabularyStore } from "@/stores/vocabularyStore";
import BaseHeader from "@/components/BaseHeader.vue";
import ActionItemCard from "@/components/ActionItemCard.vue";
import ProgressCircle from "@/components/ProgressCircle.vue";
import BaseSvg from "@/components/BaseSvg.vue";
import { User } from "@/types";

const router = useRouter();

const { isStarted, isEndingSoon, isFinished } = currentCheckinStatus();

const isLoading = ref(false);
const isFinishAnimationActive = ref(false);
const hasUserAnswers = userStore?.user?.answers?.length;

function getHomeViewProperties() {
  const {
    lastChanceTitle,
    beforeStartTitle,
    startCheckinLong,
    welcomeBackTitle,
    resumeCheckinLong,
    afterDoneTitle,
    showAllActionItems,
    noCheckinTitle,
  } = uiTranslations.value;

  let title = "";
  let buttonTarget = "";
  let buttonLabel = "";
  let isButtonShown = true;
  let isProgressCircleShown = true;

  if (!currentCheckin.value && !hasUserAnswers)
    return {
      title: noCheckinTitle,
      buttonTarget,
      buttonLabel,
      isButtonShown: false,
      isProgressCircleShown: false,
    };

  if (!isStarted) {
    title = isEndingSoon ? lastChanceTitle : beforeStartTitle;
    buttonTarget = "/checkin/start";
    buttonLabel = startCheckinLong;
  } else if (!isFinished) {
    title = isEndingSoon ? lastChanceTitle : welcomeBackTitle;
    buttonTarget = "checkin/survey";
    buttonLabel = resumeCheckinLong;
  } else {
    const comingSoonTitle = getComingSoonTitle();
    title = afterDoneTitle + (comingSoonTitle !== undefined ? " " + comingSoonTitle + "." : "");
    buttonTarget = triggeredActionItems.value.length ? "/actions" : "";
    buttonLabel = triggeredActionItems.value.length ? showAllActionItems : "";
    isButtonShown = Boolean(triggeredActionItems.value.length && !isFinishAnimationActive.value && isStandalone());
  }

  return {
    title,
    buttonTarget,
    buttonLabel,
    isButtonShown,
    isProgressCircleShown,
  };
}

const driverColors = computed(() => {
  if (userStore?.user?.preferences.checkinLightModePreference)
    return currentAndTransferedCheckinQuestions.value.all.map(
      (question: any) => driverStylesMap[question.driverId].colors.secondary,
    );

  return currentAndTransferedCheckinQuestions.value.all.map(
    (question: any) => driverStylesMap?.[question.driverId]?.colors?.primary ?? "#2B8255",
  );
});

function handleFinishLoading() {
  isFinishAnimationActive.value = true;
  setTimeout(() => {
    isFinishAnimationActive.value = false;
  }, 5000);
}

async function fetchCacheFiles(user: User) {
  isLoading.value = true;
  await vocabularyStore.fetchAndSetVocabulary(getAvailableUserLanguage(), user.segmentRevision);
  await surveyStore.fetchAndSetSurveys(user.segmentRevision);
}

function getProgressCircleType() {
  const noAnswers = currentAndTransferedCheckinQuestions.value.answered.length === 0;
  return noAnswers ? "spinner" : "progress";
}

onMounted(async () => {
  if (lastRouteName(router) === "Check-in flow" && isFinished) {
    handleFinishLoading();
  }
  if (isEmpty(vocabularyStore.vocabulary) || isEmpty(surveyStore.survey)) {
    isLoading.value = true;
    fetchCacheFiles(userStore.user);
  } else {
    // check if there is an update in segment revision
    const authUser = await userStore.getUser();
    if (authUser.segmentRevision !== userStore.user.segmentRevision) {
      isLoading.value = true;
      fetchCacheFiles(authUser);
    }
    userStore.updateUser(authUser);
  }
  isLoading.value = false;
  if (userStore?.user?._id) {
    userStore.syncUser();
  }
});
</script>

<style scoped>
.finished-circle-lock {
  animation: spin-shrink 5s linear forwards;
}

@keyframes spin-shrink {
  0% {
    transform: rotate(0deg);
    width: 100%;
  }
  50% {
    transform: rotate(360deg);
    width: 100%;
  }
  80% {
    width: 70px;
    transform: rotate(1600deg);
  }
  100% {
    transform: rotate(1690deg);
    width: 64px;
  }
}

.finished-container-lock {
  animation: height-shrink 5s ease-in-out forwards;
}

@keyframes height-shrink {
  0%,
  50% {
    height: 100%;
  }
  80% {
    height: 80%;
  }
  100% {
    height: 100px;
  }
}
</style>

import { AxiosError, AxiosResponse } from "axios";
import { HttpResponse } from "@/types";

/**
 * Fetch data from an API and return a fallback value if the user is offline
 * @param fetchFn The function to fetch data from the API
 * @param fallback The value to return if the user is offline
 * @returns The response from the API or the fallback value
 * @template T The type of the data to fetch
 * @example await fetchOrFallback<Survey>(await axiosHelper.get(`/surveys/cache`), surveyStore.survey);
 */
export async function fetchOrFallback<T>(
  fetchFn: () => Promise<HttpResponse<T>>,
  fallback: T,
): Promise<HttpResponse<T>> {
  if (!navigator.onLine) {
    return {
      data: fallback,
      message: "No internet connection",
      success: false,
    };
  } else {
    try {
      const response = (await fetchFn) as unknown as AxiosResponse<HttpResponse<T>>;
      // We need to handle r2 API responses differently. I am not proud of this. I am sorry.
      // TODO: Refactor this to be more generic/descriptive
      return {
        data: "data" in response.data ? response.data.data : response.data,
        message: "",
        success: true,
      };
    } catch (err) {
      console.error("An error occured when fetching data. Error: ", JSON.stringify(err, null, 2));
      const error = err as AxiosError<HttpResponse<T>>;
      return {
        data: fallback,
        message: error.response?.data.message || "An error occurred",
        success: false,
      };
    }
  }
}

import { reactive, watchEffect } from "vue";
import { Survey } from "@/types";
import { env } from "@/utils";
import { fetchOrFallback } from "@/helpers/fetchHelper";
import axiosHelper from "@/helpers/axiosHelper";

const savedSurvey = localStorage.getItem("survey");

const initialState = savedSurvey ? JSON.parse(savedSurvey) : {};

const surveyState = reactive<Survey>(initialState);

function saveSurveyToLocalStorage(survey: Survey) {
  localStorage.setItem("survey", JSON.stringify(survey));
}

function updateSurvey(data: Survey) {
  Object.assign(surveyState, data);
}

async function fetchAndSetSurveys(segmentRevision: string) {
  const url = `${env("VITE_R2_URL")}/survey_${segmentRevision}.json`;
  const response = await fetchOrFallback<Survey>(await axiosHelper.get(url), surveyStore.survey);
  const fetchedSurvey = response.data;
  updateSurvey(fetchedSurvey);
  saveSurveyToLocalStorage(fetchedSurvey);
}

watchEffect(async () => {
  saveSurveyToLocalStorage(surveyState);
});

interface surveyStore {
  survey: Survey;
  updateSurvey: (data: Survey) => void;
  fetchAndSetSurveys: (segmentRevision: string) => Promise<void>;
}

export const surveyStore: surveyStore = {
  survey: surveyState,
  updateSurvey,
  fetchAndSetSurveys,
};

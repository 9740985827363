import { createWebHistory, createRouter, RouteLocationNormalized, NavigationGuardNext } from "vue-router";
import { userStore } from "@/stores/userStore";
import DownloadWrapper from "@/views/DownloadWrapper.vue";
import HomeView from "@/views/HomeView.vue";
import SettingsView from "@/views/SettingsView.vue";
import CheckinStartView from "@/views/CheckinStartView.vue";
import CheckinFlowView from "@/views/CheckinFlowView.vue";
import ActionsView from "@/views/ActionsView.vue";
import ActionItemView from "@/views/ActionItemView.vue";
import OnboardingSignup from "@/views/OnboardingSignup.vue";
import OnboardingView from "@/views/OnboardingView.vue";
import CheckinAuthenticateView from "@/views/CheckinAuthenticateView.vue";
import RequestAuthLinkView from "@/views/RequestAuthLinkView.vue";
import AuthenticateMagiclink from "@/views/AuthenticateMagiclink.vue";
import axiosHelper from "@/helpers/axiosHelper";

const routes = [
  {
    path: "/",
    redirect: "/onboarding/pvzg.hr",
  },
  {
    path: "/magiclink/:hash",
    name: "MagicLink",
    component: AuthenticateMagiclink,
  },
  {
    path: "/home",
    name: "Home",
    component: HomeView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/uid/:uid",
    name: "Download",
    props: true,
    component: DownloadWrapper,
    meta: {
      requiresHash: true,
      requiresAuth: false,
    },
  },
  {
    path: "/settings",
    name: "Settings",
    component: SettingsView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/onboarding",
    children: [
      {
        path: "",
        name: "Onboarding view",
        component: OnboardingView,
      },
      {
        path: ":hostname",
        name: "Onboarding",
        component: OnboardingSignup,
      },
      {
        path: ":hostname/done",
        name: "Onboarding complete",
        component: OnboardingSignup,
      },
    ],
  },
  {
    path: "/auth",
    children: [
      {
        path: "web",
        name: "Authenticate",
        component: CheckinAuthenticateView,
      },
      {
        path: "request",
        name: "Request link",
        component: RequestAuthLinkView,
      },
    ],
  },
  {
    path: "/checkin",
    children: [
      {
        path: "start",
        name: "Start check-in",
        component: CheckinStartView,
      },
      {
        path: "survey",
        name: "Check-in flow",
        component: CheckinFlowView,
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
  {
    path: "/actions",
    children: [
      {
        path: "",
        name: "Actions",
        component: ActionsView,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: ":id",
        name: "Action Item",
        component: ActionItemView,
        props: true,
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
];

if (["staging", "local"].includes(import.meta.env.VITE_ENVIRONMENT)) {
  routes.push({
    path: "/internal-auth/:name",
    name: "Internal Auth",
    // @ts-expect-error - TS complains about the beforeEnter function not being a NavigationGuard
    beforeEnter: (to: RouteLocationNormalized, _from: RouteLocationNormalized, next: NavigationGuardNext) => {
      axiosHelper.get("/auth/internal?email=" + to.params.name + "@studentpulse.io").then((response) => {
        if (response.data?.success) {
          userStore.updateUser(response.data.data);
          next({ name: "Home" });
        } else {
          next();
        }
      });
    },
  });
}
const router = createRouter({
  history: createWebHistory(),
  routes,
});
export default router;

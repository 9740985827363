<template>
  <div class="flex h-0.5 flex-grow items-center justify-between gap-2">
    <div
      v-for="(step, index) in props.totalSteps"
      :key="index"
      class="h-full grow rounded-l-full rounded-r-full bg-interface-0 transition-opacity"
      :style="'transition-duration:' + props.transitionDuration + 'ms'"
      :class="{ 'opacity-20': step > props.currentStep }"></div>
  </div>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    totalSteps: number;
    transitionDuration: number;
    currentStep: number;
  }>(),
  {
    currentStep: 0,
  },
);
</script>

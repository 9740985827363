<template>
  <!-- #FEFDFD is hardcoded to match /bg_download.png's color -->
  <div class="fixed inset-0 h-screen w-screen items-center justify-center bg-[#FEFDFD] text-interface-1000">
    <img src="/bg_download.png" class="pointer-events-none absolute bottom-0 right-0 -z-50 h-full object-cover" />
    <div
      class="absolute bottom-0 left-1/2 mx-auto flex h-full max-h-[1000px] w-full max-w-screen-2xl -translate-x-1/2 items-center justify-between px-12 pt-12">
      <section class="flex h-full w-full max-w-md flex-col items-center justify-between gap-16">
        <div class="h-full">
          <p class="mb-2.5 text-xl text-interface-500">{{ uiTranslations.welcomeTo }} {{ inject("providerName") }}</p>
          <p class="mb-9 font-serif text-4.5xl font-normal leading-tighter tracking-tight">
            {{ uiTranslations.addToHomeScreenTitle }}:
          </p>
          <div class="flex flex-col gap-5 *:flex *:items-center *:gap-3">
            <div>
              <BaseSvg name="hand-pointing-icon" class="w-9 shrink-0 text-ios-blue" />
              <p class="text-xl text-interface-500">{{ uiTranslations.downloadBenefit1 }}</p>
            </div>

            <div>
              <BaseSvg name="play-pause-icon" class="w-9 shrink-0 text-ios-blue" />
              <p class="text-xl text-interface-500">{{ uiTranslations.downloadBenefit2 }}</p>
            </div>

            <div>
              <BaseSvg name="hand-heart-icon" class="w-9 shrink-0 text-ios-blue" />
              <p class="text-xl leading-5 text-interface-500">
                {{ uiTranslations.downloadBenefit3 }}
              </p>
            </div>
          </div>
        </div>
        <img src="/phone_mockup.png" class="pointer-events-none" />
      </section>
      <section class="flex h-full max-h-[500px] w-full flex-col items-center justify-between text-center">
        <div
          class="flex flex-col rounded-2xl bg-interface-25 text-black shadow-[0px_-34px_56px_-15px,0px_0px_20px_-5px] shadow-interface-750/20">
          <div class="flex flex-col items-start justify-center p-4 text-xs">
            <img :src="qrCodeDataURL" alt="App QR Code" class="pointer-events-none max-w-64" />
          </div>
        </div>

        <div class="flex flex-col items-center">
          <p class="text-2xl font-medium">{{ uiTranslations.scanQRToInstall }}</p>
          <RouterLink
            v-if="currentCheckin"
            :to="{ name: 'Authenticate', params: { hash: route.params.uid } }"
            class="mx-6 mt-4 flex cursor-pointer items-center gap-1 py-2.5">
            <p class="font-medium text-interface-350 underline">
              {{ uiTranslations.noThanksContinueInBrowser }}
            </p>
            <BaseSvg name="arrow-right-icon" class="w-6 text-interface-350" />
          </RouterLink>
        </div>
      </section>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref, inject } from "vue";
import QRCode from "qrcode";
import { useRoute } from "vue-router";
import { uiTranslations } from "@/helpers/languageHelpers.ts";
import BaseSvg from "@/components/BaseSvg.vue";
import { currentCheckin } from "@/helpers/checkinHelpers.ts";

const route = useRoute();

const qrCodeDataURL = ref("");

async function generateQR() {
  try {
    const dataURL = await QRCode.toDataURL(window.location.href, {
      width: 500,
      margin: 0,
      errorCorrectionLevel: "H",
      color: {
        dark: "#000000",
        light: "#0000", // Transparent background
      },
    });

    qrCodeDataURL.value = dataURL;
  } catch (err) {
    console.error(err);
  }
}

onMounted(() => {
  generateQR();
});
</script>

<style scoped>
/* override safe-area-inset */
.safe-area-inset {
  padding-top: 0;
  padding-bottom: 0;
}
</style>

import { computed } from "vue";
import { userStore } from "@/stores/userStore";
import { surveyStore } from "@/stores/surveyStore";
import { vocabularyStore } from "@/stores/vocabularyStore";
import { uiTranslations } from "./languageHelpers";

import { Checkin, SurveyQuestion, Answer, CheckinStatus, BreakPeriod, VocabularyQuestion } from "@/types";
import { isEmpty, isTomorrow, getDifferenceFromTodayInDays } from "@/utils";

const todayDate = new Date();

// CHECKINS

export const currentCheckin = computed((): Checkin | undefined => {
  if (currentBreakPeriod()) {
    return undefined;
  } else {
    return getRelevantCheckin();
  }
});

export const currentAndTransferedCheckinQuestions = computed(() => {
  const amountToTransfer = 3;

  if (!currentCheckin.value) return { all: [], answered: [] };
  const questions = unansweredTransferableQuestions().slice(0, amountToTransfer);

  const all = questions.concat(currentCheckin.value.questions);
  const answered = all.filter((question: SurveyQuestion) => {
    const isAnswered = userStore?.user?.answers.some((answer: Answer) => {
      return answer.questionId === question._id && answer.checkinId === question.checkinId;
    });

    return isAnswered;
  });

  return { all, answered };
});

export function currentCheckinAnswers(): Answer[] {
  const answeredQuestions = currentAndTransferedCheckinQuestions.value.answered;
  if (!userStore?.user?.answers) return [];

  return userStore.user.answers.filter((answer) => {
    return answeredQuestions.some((question: SurveyQuestion) => {
      return answer.questionId === question._id && answer.checkinId === question.checkinId;
    });
  });
}

const enrichedCheckins = computed<Checkin[]>(() => {
  if (!surveyStore.survey?.checkins || !vocabularyStore.vocabulary?.questions) return [];
  return surveyStore.survey?.checkins?.map((checkin: Checkin) => {
    return {
      ...checkin,
      questions: checkin.questions.map((question: SurveyQuestion) => {
        const vocabularyQuestion = vocabularyStore.vocabulary.questions.find(
          (vocabQuestion: VocabularyQuestion) => vocabQuestion._id === question._id,
        );

        return {
          ...question,
          checkinId: checkin._id,
          driverId: vocabularyQuestion?.driverId,
        };
      }),
    };
  });
});

function currentBreakPeriod(): BreakPeriod | undefined {
  const breakPeriods = surveyStore?.survey?.breakPeriods;
  if (!breakPeriods) return undefined;

  return breakPeriods.find((period) => {
    const startDate = new Date(period.startDate);
    const endDate = new Date(period.endDate);
    return todayDate >= startDate && todayDate <= endDate;
  });
}

function getRelevantCheckin() {
  return enrichedCheckins.value.find((checkin: Checkin) => {
    const startDate = new Date(checkin.startDate);
    const endDate = new Date(checkin.endDate);
    return todayDate >= startDate && todayDate <= endDate;
  });
}

const nextCheckin = computed<Checkin | undefined>(() => {
  if (!enrichedCheckins.value.length) return undefined;
  let nextCheckin;
  let earliestDate;

  for (let i = 0; i < enrichedCheckins.value.length; i++) {
    const checkin = enrichedCheckins.value[i];
    const startDate = new Date(checkin.startDate);
    const isEarliestDate = earliestDate === undefined || startDate.getTime() < earliestDate;

    if (todayDate < startDate && isEarliestDate) {
      nextCheckin = checkin;
      earliestDate = startDate.getTime();
    }
  }
  return nextCheckin;
});

export function currentCheckinStatus(): CheckinStatus {
  const targetDays = 3;

  if (isEmpty(vocabularyStore.vocabulary) || isEmpty(surveyStore.survey))
    return { isStarted: false, isFinished: false, isEndingSoon: false };

  if (!currentCheckin.value) {
    return {
      isStarted: true,
      isFinished: true,
      isEndingSoon: false,
    };
  }

  const questions = currentAndTransferedCheckinQuestions.value;
  const currentCheckinEndDate = new Date(currentCheckin.value.endDate);

  const isStarted = questions.answered.length !== 0;
  const isFinished = questions.answered.length === questions.all.length;
  const isEndingSoon = getDifferenceFromTodayInDays(currentCheckinEndDate) <= targetDays;
  return { isStarted, isFinished, isEndingSoon };
}

function getDaysUntilNextCheckin(): number {
  if (!nextCheckin.value || isEmpty(vocabularyStore.vocabulary) || isEmpty(surveyStore.survey)) return -1;
  const nextCheckinStartDate = new Date(nextCheckin.value.startDate);
  return getDifferenceFromTodayInDays(nextCheckinStartDate);
}

export function getComingSoonTitle() {
  if (!nextCheckin.value) return undefined;
  const nextCheckinStartDate = new Date(nextCheckin.value.startDate);

  const daysUntilNextCheckin = Math.ceil(getDaysUntilNextCheckin());
  const startTomorrow = uiTranslations.value.newCheckinOpens + uiTranslations.value.tomorrow;
  const startInXDays =
    uiTranslations.value.newCheckinOpens + uiTranslations.value.inXdays.replace("X", daysUntilNextCheckin.toString());

  if (daysUntilNextCheckin < 0) return undefined;
  if (isTomorrow(nextCheckinStartDate)) return startTomorrow;
  return startInXDays;
}

function unansweredTransferableQuestions() {
  if (!currentCheckin.value) return [];
  const pastCheckins = enrichedCheckins.value.filter((checkin) => {
    // @ts-expect-error Object is possibly 'null'.
    return new Date(checkin.endDate) < new Date(currentCheckin.value.startDate);
  });

  const transferablePastQuestions = pastCheckins.flatMap((checkin: Checkin) =>
    checkin.questions.filter((question: SurveyQuestion) => question.isTransferable),
  );

  return transferablePastQuestions.filter((question: SurveyQuestion) => {
    const answers = userStore?.user?.answers || [];

    return !answers.some((answer: Answer) => {
      const answerDate = new Date(answer.createdAt);
      const checkinStartDate = new Date(currentCheckin.value?.startDate || new Date());

      return (
        answer.questionId === question._id && answer.checkinId === question.checkinId && answerDate < checkinStartDate
      );
    });
  });
}

<template>
  <main class="h-full w-full flex-col items-center justify-between gap-6 overflow-hidden">
    <div class="flex h-full w-full max-w-7xl flex-col px-8 pt-8 tracking-tight" @click="focusInput">
      <textarea
        @blur="focusInput"
        ref="commentField"
        :placeholder="uiTranslations.typeHere"
        v-model="commentInput"
        :autofocus="props.hasFocus"
        :class="inputFontSize"
        class="mb-3 w-full resize-none rounded border-none bg-transparent text-center font-serif font-light outline-none transition-all duration-1000 placeholder:text-white/40">
      </textarea>
      <h3 class="system-transition mx-16 mb-viewportBottom text-center text-base font-light">
        {{ props.commentTitle }}
      </h3>
    </div>

    <div class="system-transition fixed bottom-viewportBottom right-0">
      <button
        @click="commentInput.length ? emit('handleCommentInput', commentInput) : null"
        :class="
          commentInput.length === 0
            ? ' bg-interface-0/40 text-interface-750 text-opacity-60'
            : 'bg-interface-0 text-interface-750'
        "
        class="mb-2.5 mr-5 w-16 rounded-full p-3 text-base transition-all duration-200">
        <BaseSvg name="arrow-right-icon" />
      </button>
    </div>
  </main>
</template>

<script setup lang="ts">
import { Answer } from "@/types";
import { computed, nextTick, onMounted, ref } from "vue";
import BaseSvg from "@/components/BaseSvg.vue";
import { uiTranslations } from "@/helpers/languageHelpers";

const props = defineProps<{
  commentTitle: string;
  hasFocus: boolean;
  oldAnswer?: Answer;
}>();
const emit = defineEmits(["handleCommentInput"]);

const commentInput = ref<string>("");
const commentField = ref<HTMLTextAreaElement | null>(null);

const inputFontSize = computed(() => {
  return {
    "text-4xl flex-grow-0": commentInput.value.length <= 10,
    "text-3xl flex-grow": commentInput.value.length > 10 && commentInput.value.length <= 100,
    "text-2xl flex-grow": commentInput.value.length > 100 && commentInput.value.length <= 150,
    "text-lg flex-grow": commentInput.value.length > 150,
  };
});

const focusInput = () => {
  console.log("try auto focus to textarea", commentField.value);
  nextTick(() => {
    commentField.value?.focus();
  });
};

onMounted(() => {
  console.log("mounted, focus textarea!");
  if (props.hasFocus) focusInput();

  commentInput.value = props.oldAnswer?.comment === undefined ? "" : props.oldAnswer.comment;
});
</script>

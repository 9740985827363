<template>
  <Transition name="slide-right">
    <div>
      <div class="flex h-full w-full flex-col">
        <BaseHeader :headerTitle="uiTranslations.settings" class="mb-5 capitalize">
          <template #leftIcon>
            <RouterLink to="/home" class="p-1">
              <div class="relative">
                <BaseSvg name="caret-left-icon" class="w-7" />
              </div>
            </RouterLink>
          </template>
        </BaseHeader>

        <section class="w-full">
          <div class="px-5 py-4">
            <h2 class="text-xs font-medium uppercase text-interface-350">
              {{ uiTranslations.preferences }}
            </h2>
          </div>
          <div class="flex items-center border-b border-interface-750 px-5" @click="isLanguageSettingsModalOpen = true">
            <div class="flex grow items-center gap-5">
              <BaseSvg name="language-icon" class="w-6 text-interface-25" />

              <div class="py-4 font-medium capitalize text-interface-25">
                {{ uiTranslations.language }}
              </div>
            </div>

            <div class="flex items-center justify-end gap-2.5 font-normal">
              <div class="text-interface-350">
                {{ getLanguageNameFromLanguageCode(getAvailableUserLanguage()).inNative }}
              </div>
              <BaseSvg name="caret-right-icon" class="w-6 text-interface-50" />
            </div>
          </div>

          <div class="flex items-center border-b border-t border-interface-750 px-5">
            <div class="flex grow items-center gap-5">
              <BaseSvg name="sun-icon" class="w-6 text-interface-25" />

              <div class="py-4 font-medium text-interface-25">
                {{ uiTranslations.toggleLightModeInCheckinsLabel }}
              </div>
            </div>

            <div class="flex items-center justify-end">
              <div class="flex items-center gap-2.5">
                <label class="relative flex items-center justify-between p-1 text-xl">
                  <input
                    type="checkbox"
                    v-model="checkinLightModePreference"
                    @change="setCheckinThemePreferences()"
                    class="peer sr-only absolute left-1/2 h-full w-full -translate-x-1/2 rounded-md" />
                  <span
                    class="flex h-7 w-12 flex-shrink-0 items-center rounded-full bg-gray-300 p-1 duration-300 ease-in-out after:h-5 after:w-5 after:rounded-full after:bg-white after:shadow-md after:duration-300 peer-checked:bg-ios-green peer-checked:after:translate-x-5"></span>
                </label>
              </div>
            </div>
          </div>
        </section>

        <section class="w-full">
          <div class="px-5 py-4">
            <h2 class="text-xs uppercase text-interface-350">
              {{ uiTranslations.info }}
            </h2>
          </div>

          <div
            class="flex items-center border-b border-t border-interface-750 px-5"
            @click="isAboutSettingsModalOpen = true">
            <div class="flex grow items-center gap-5">
              <BaseSvg name="info-icon" class="w-6 text-interface-25" />

              <div class="py-4 font-medium capitalize text-interface-25">
                {{ uiTranslations.about }}
              </div>
            </div>

            <div class="flex items-center justify-end">
              <div>
                <BaseSvg name="caret-right-icon" class="w-6 text-interface-50" />
              </div>
            </div>
          </div>
        </section>
      </div>

      <Transition name="slide-right">
        <div
          v-if="isLanguageSettingsModalOpen"
          class="safe-area-inset absolute inset-0 top-0 flex h-full w-full flex-col bg-interface-1000">
          <BaseHeader :headerTitle="uiTranslations.language" class="mb-5 capitalize">
            <template #leftIcon>
              <div class="p-1" @click="isLanguageSettingsModalOpen = false">
                <div class="relative">
                  <BaseSvg name="caret-left-icon" class="w-7" />
                </div>
              </div>
            </template>
          </BaseHeader>

          <section class="flex flex-col gap-2">
            <div class="px-5 py-4">
              <h2 class="text-xs font-medium uppercase text-interface-350">
                {{ uiTranslations.allLanguages }}
              </h2>
            </div>
            <div class="mx-5 rounded-xl bg-interface-500">
              <div
                v-for="languageCode in availableContentLanguages"
                @click="
                  setUserLanguagePreferences(languageCode);
                  isLanguageSettingsModalOpen = false;
                "
                class="mx-4 flex items-center justify-between border-b border-interface-350 py-2.5 last:border-none">
                <div class="flex flex-col gap-1">
                  <div class="font-medium">
                    {{ getLanguageNameFromLanguageCode(languageCode).inNative }}
                  </div>
                  <div class="text-xs text-interface-250">
                    {{ getLanguageNameFromLanguageCode(languageCode).inEnglish }}
                  </div>
                </div>

                <BaseSvg
                  name="checkmark-icon"
                  class="w-[33px] transition-opacity"
                  :class="getAvailableUserLanguage() === languageCode ? 'opacity-100' : 'opacity-0'" />
              </div>
            </div>
          </section>
        </div>
      </Transition>

      <Transition name="slide-right">
        <div
          v-if="isAboutSettingsModalOpen"
          class="safe-area-inset fixed inset-0 top-0 flex h-full w-full flex-col bg-interface-1000">
          <BaseHeader :headerTitle="uiTranslations.about" class="mb-5 capitalize">
            <template #leftIcon>
              <div class="p-1" @click="isAboutSettingsModalOpen = false">
                <div class="relative">
                  <BaseSvg name="caret-left-icon" class="w-7" />
                </div>
              </div>
            </template>
          </BaseHeader>

          <section class="flex flex-col gap-2">
            <div class="px-5 py-4">
              {{ uiTranslations.aboutDescription }}
            </div>
          </section>
        </div>
      </Transition>
    </div>
  </Transition>
</template>

<script setup lang="ts">
import { ref } from "vue";
import {
  uiTranslations,
  availableContentLanguages,
  getLanguageNameFromLanguageCode,
  getAvailableUserLanguage,
  setUserLanguagePreferences,
} from "@/helpers/languageHelpers.ts";

import { userStore } from "@/stores/userStore";

import BaseHeader from "@/components/BaseHeader.vue";
import BaseSvg from "@/components/BaseSvg.vue";

const checkinLightModePreference = ref(userStore?.user?.preferences.checkinLightModePreference);

const setCheckinThemePreferences = () => {
  const updatedUser = {
    ...userStore.user,
    preferences: {
      ...userStore?.user?.preferences,
      checkinLightModePreference: checkinLightModePreference.value,
    },
  };
  userStore.updateUser(updatedUser);
  userStore.updateConfig();
};

const isLanguageSettingsModalOpen = ref(false);
const isAboutSettingsModalOpen = ref(false);
</script>

<template>
  <router-view v-slot="{ Component }" class="w-screen" :class="isStandalone() ? 'safe-area-inset h-lvh' : 'h-svh'">
    <Transition name="fade-opacity" mode="out-in" :duration="100">
      <div>
        <component :is="Component"></component>
        <BackgroundGradient
          v-if="route.name === 'Check-in flow' || route.name === 'Start check-in'"
          :currentComponent="Component"></BackgroundGradient>
      </div>
    </Transition>
  </router-view>

  <div
    v-if="env('MODE') === 'development' && env('VITE_DEV_SHOW_DEBUGBAR')"
    class="pointer-events-none absolute left-0 top-0 z-50 w-screen break-all bg-emerald-200/50 p-2 text-xxs font-medium">
    <div>dom url: {{ currentURL }}</div>
    <div class="flex gap-10">
      <div>route path: {{ route.path }}</div>
      <div>user id: {{ userStore?.user._id }}</div>
      <div>isstandalone: {{ isStandalone() }}</div>
    </div>
  </div>
  <UpdateSw @hide="showUpdateSw = false" v-if="needRefresh && showUpdateSw" />
</template>

<script setup lang="ts">
import { onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import { userStore } from "@/stores/userStore";
import { env, registerResizeEventListener } from "@/utils";
import { isStandalone } from "@/helpers/deviceHelpers";
import BackgroundGradient from "@/components/BackgroundGradient.vue";
import UpdateSw from "@/components/UpdateSw.vue";
import { useRegisterSW } from "virtual:pwa-register/vue";

const showUpdateSw = ref(true);
const { needRefresh } = useRegisterSW();

const route = useRoute();
const currentURL = ref("");
onMounted(() => {
  currentURL.value = window.location.href;
  registerResizeEventListener();
});
</script>

import axiosHelper from "@/helpers/axiosHelper";

export function registerEventListeners() {
  navigator.serviceWorker?.addEventListener("message", (event) => {
    if (event.data.type === "SW_CREATE_SUBSCRIPTION") {
      axiosHelper.post("/participants/push-notifications", {
        subscription: event.data.data,
      });
    }
  });
}

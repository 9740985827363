<template>
  <div class="flex h-svh flex-col justify-between overflow-hidden">
    <Transition name="fade-opacity" mode="out-in" :duration="200">
      <section
        v-if="route.path === `/onboarding/${route.params.hostname}`"
        class="z-20 flex w-full flex-col gap-8 px-6 pb-4">
        <div class="">{{ organization?.name ?? "&nbsp;" }}</div>
        <div class="text-balance font-serif text-4xl leading-tighter">
          {{ uiTranslations.createYourFree }}
          {{ inject("providerName") }}{{ uiTranslations.xAccount }}
        </div>
        <div class="text-interface-250">
          <div class="flex justify-start rounded-md border border-white/50 bg-white/20 p-3 pt-4">
            <span
              class="input-wrapper relative inline-block h-full max-w-full overflow-hidden text-ellipsis py-1 leading-4"
              :class="{ 'w-full': !organization?.forceDomain }">
              <span
                v-if="organization?.forceDomain"
                class="pointer-events-none relative inline-block whitespace-pre text-transparent"
                >{{ placeholder }}</span
              >
              <input
                class="!focus:outline-0 caret-brand-green-dark inline !h-auto w-full text-ellipsis border-0 bg-transparent text-start lowercase leading-4 text-white outline-none !outline-0"
                :class="organization?.forceDomain ? 'absolute left-0 top-0 min-w-2' : ''"
                type="text"
                :maxlength="organization?.forceDomain ? 20 : 80"
                v-model="inputMail"
                @input="updateChange"
                :placeholder="placeholder"
                inputMode="email"
                spellcheck="false"
                autocomplete="off"
                autofocus />
            </span>
            <span v-if="organization?.forceDomain" class="inline-block h-full justify-center pl-1 text-white/50"
              >@{{ route.params?.hostname }}</span
            >
          </div>
          <small v-if="organization?.forceDomain" class="block pt-2 italic"
            >{{ uiTranslations.typeYourX }} @{{ route.params?.hostname }} {{ organization?.forceDomain }}
            {{ uiTranslations.emailAddress }}</small
          ><small v-else class="block pt-2 italic"
            >{{ uiTranslations.typeYourX }} {{ uiTranslations.emailAddress }}</small
          >
        </div>

        <div class="items-start">
          <input type="checkbox" :value="approveTerms" v-model="approveTerms" /> {{ uiTranslations.iAcceptThe }}
          <a :href="termsAndConditionsUrl" target="_blank" class="underline">{{ uiTranslations.termsAndConditions }}</a>
        </div>

        <div v-if="errorMessages" class="w-full rounded-md border border-red-500/20 bg-red-500/20 p-4 text-red-300">
          {{ errorMessages }}
        </div>

        <div class="absolute bottom-viewportBottom left-0 w-full p-6 pb-12">
          <button
            @click.prevent="submitSignup"
            class="w-full rounded-lg bg-interface-0 py-3 text-center font-bold text-interface-750">
            {{ uiTranslations.getFreeAccess }}
          </button>
        </div>
      </section>
      <section
        v-else-if="route.path === `/onboarding/${route.params.hostname}/done`"
        class="z-20 flex w-full flex-col px-6 pb-4">
        <div>
          <div class="mb-6">{{ organization?.name ?? "&nbsp;" }}</div>
          <div class="text-balance font-serif text-4xl leading-tighter">{{ uiTranslations.checkYourInbox }}</div>
        </div>
      </section>
    </Transition>
  </div>
</template>

<script lang="ts" setup>
import { inject, computed, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import axiosHelper from "@/helpers/axiosHelper";
import { uiTranslations } from "@/helpers/languageHelpers";

const route = useRoute();
const router = useRouter();
const inputMail = ref<string>("");
const placeholder = ref<string>("Email");
const approveTerms = ref<boolean>(false);
const errorMessages = ref<string>("");

const termsAndConditionsUrl = "https://legal.studentpulse.io/2607468052";

const orgs: { [key: string]: { name: string; forceDomain?: boolean } } = {
  "studentpulse.io": { name: "StudentPulse A/S", forceDomain: true },
  "pvzg.hr": { name: "Zagreb School of Business", forceDomain: false },
};

function updateChange(event: any) {
  placeholder.value = event.target.value;
}

function submitSignup() {
  if (!inputMail.value)
    return (errorMessages.value = `${uiTranslations.value.typeYourX} ${uiTranslations.value.emailAddress}`);
  if (!approveTerms.value) return (errorMessages.value = uiTranslations.value.pleaseAcceptTC);

  errorMessages.value = "";
  let emailValue = inputMail.value;
  if (organization.value?.forceDomain) emailValue += "@" + route.params?.hostname;
  axiosHelper.post(
    "/auth/request-link",
    {
      email: emailValue,
      language: route.params?.hostname === "pvzg.hr" ? "hr-HR" : "en-GB",
    },
    // {
    //   headers: {
    //     locale: route.params.hostname === "pvzg.hr" ? "hr-HR" : "en-GB",
    //   },
    // },
  );
  router.push(`/onboarding/${route.params?.hostname}/done`);
}

let organization = computed(() => {
  if (!orgs[route.params?.hostname as string]) return;

  return orgs[route.params?.hostname as string];
});
</script>

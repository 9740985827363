<template>
  <div>
    <div class="flex h-full w-full flex-col">
      <BaseHeader :headerTitle="uiTranslations.actionItemPageTitle" class="mb-5">
        <template #leftIcon>
          <RouterLink to="/home" class="p-1">
            <div class="relative">
              <BaseSvg name="caret-left-icon" class="w-7" />
            </div>
          </RouterLink>
        </template>
      </BaseHeader>
      <section class="flex flex-col gap-10 px-4 pb-12">
        <div v-for="(months, key) in groupedActions" class="relative overflow-y-scroll">
          <div class="mb-6 text-2xl font-medium">{{ key }}</div>
          <div class="flex flex-col-reverse">
            <div v-for="checkin in months" class="flex flex-col">
              <div class="flex flex-col">
                <div v-for="(actions, key) in checkin">
                  <div class="mb-2.5 flex items-center gap-5">
                    <div>
                      <div class="h-2.5 w-2.5 rounded-full border bg-none"></div>
                    </div>
                    <div class="text-xs font-medium">{{ getCheckinStartDateById(key).month }}</div>
                  </div>
                  <div
                    class="mb-2.5 flex h-8 items-center gap-5 overflow-clip"
                    :style="{ height: actions.length * 80 + 'px' }">
                    <div
                      class="ml-1 h-full w-0.5 opacity-30"
                      style="
                        background: repeating-linear-gradient(white, white 10px, transparent 10px, transparent 25px);
                      "></div>
                    <div class="flex w-full flex-col gap-2">
                      <ActionItemCard v-for="action in actions" :actionItem="action" size="small" :key="action._id" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script setup lang="ts">
import { uiTranslations } from "@/helpers/languageHelpers.ts";
import BaseHeader from "@/components/BaseHeader.vue";
import BaseSvg from "@/components/BaseSvg.vue";
import { userStore } from "@/stores/userStore";
import { vocabularyStore } from "@/stores/vocabularyStore";
import { surveyStore } from "@/stores/surveyStore";
import { getAvailableUserLanguage } from "@/helpers/languageHelpers.ts";

import { computed } from "vue";

import ActionItemCard from "@/components/ActionItemCard.vue";
import { ActionItem, Answer } from "@/types";

const triggerableAnswers = computed(() => {
  if (!vocabularyStore?.vocabulary?.actions || !userStore?.user?.answers) return [];
  const triggerQuestionIds = vocabularyStore?.vocabulary?.actions.flatMap((action: any) =>
    action.triggers.map((trigger: any) => trigger.questionId),
  );

  return userStore?.user?.answers.filter((answer) => triggerQuestionIds.includes(answer.questionId));
});

const groupedActions = computed(() => {
  type GroupedType = {
    [year: number]: {
      [month: number]: {
        [checkinId: number | string]: ActionItem[];
      };
    };
  };

  const grouped: GroupedType = {};

  if (!triggerableAnswers.value.length) return {};

  for (const answer of triggerableAnswers.value) {
    const actions = findActions(answer);
    if (actions.length > 0) {
      const startDate = getCheckinStartDateById(answer.checkinId).date;
      if (!startDate) continue;
      const year = startDate.getFullYear();
      const month = startDate.getMonth() + 1;

      if (!grouped[year]) {
        grouped[year] = {};
      }
      if (!grouped[year][month]) {
        grouped[year][month] = {};
      }
      if (!grouped[year][month][answer.checkinId]) {
        grouped[year][month][answer.checkinId] = [];
      }
      actions.forEach((action) => grouped[year][month][answer.checkinId].push(action));
    }
  }

  return grouped;
});

const getCheckinStartDateById = (id: string | number) => {
  if (!surveyStore?.survey?.checkins) return { date: null, month: "" };
  const checkin = surveyStore.survey.checkins.find((checkin: any) => checkin._id === id);
  const date = new Date(checkin!.startDate);
  const month = date.toLocaleString(getAvailableUserLanguage(), { month: "long", day: "numeric" });
  return { date: date, month: month };
};

function findActions(answer: Answer) {
  if (!answer || answer.rating === undefined) {
    return [];
  }
  const matchingActions = [];
  for (const action of vocabularyStore?.vocabulary?.actions) {
    for (const trigger of action.triggers) {
      if (trigger.questionId === answer.questionId && trigger.ratings.includes(answer.rating)) {
        matchingActions.push(action);
        break; // don't trigger the same action multiple times for the same answer.
      }
    }
  }
  return matchingActions;
}
</script>

<template>
  <div class="flex h-svh flex-col items-center justify-between overflow-hidden">
    <BaseHeader class="mb-5">
      <template #leftIcon>
        <Transition name="fade-opacity">
          <BaseSvg v-if="currentStep !== 1" name="caret-left-icon" @click="currentStep--" class="w-7 cursor-pointer" />
        </Transition>
      </template>

      <slot>
        <BaseProgressSteps :totalSteps="5" :transitionDuration="800" :currentStep="currentStep" class="mx-2" />
      </slot>

      <template #rightIcon>
        <div class="text-sm text-interface-250">{{ currentStep }}/5</div>
      </template>
    </BaseHeader>

    <Transition name="fade-opacity" mode="out-in" :duration="200">
      <div v-if="currentStep === 1" class="mx-5 flex h-full flex-col justify-between">
        <div class="mx-2">
          <p class="mb-8 text-balance font-serif text-3.5xl leading-tighter">
            {{ uiTranslations.welcomeTo }} {{ inject("providerName") }} 👋
          </p>
          <p class="leading-tight text-interface-250">{{ uiTranslations.onboardingStartMessage }}</p>
        </div>

        <div class="system-transition absolute bottom-viewportBottom left-0 w-full px-6">
          <button
            v-if="!isiOSWebView(userAgentSpecs.currentDevice, userAgentSpecs.currentBrowser)"
            @click="currentStep = 2"
            class="mb-12 mt-7 w-full rounded-lg bg-interface-0 py-3 text-center font-bold text-interface-750">
            {{ uiTranslations.letsGo }}
          </button>
          <div v-else class="flex flex-col items-end gap-3">
            <div
              class="arrow-bottom-right flex w-full items-center justify-center gap-2 rounded-3xl bg-interface-0 p-5 text-lg font-semibold text-interface-1000">
              {{ uiTranslations.tapOnThe }}
              <BaseSvg name="ios-compass-icon" class="aspect-square h-line shrink-0 text-ios-blue" />
              {{ uiTranslations.below }}
            </div>
            <BaseSvg name="curly-arrow-right" class="mb-5 mr-8 h-16 w-16 shrink-0 rotate-90 text-ios-blue" />
          </div>
        </div>
      </div>

      <div v-else-if="currentStep === 2" class="mx-5 overflow-scroll pb-[500px]">
        <!-- if everything is supported -->
        <template v-if="isBrowserSupported && isOsSupported">
          <section
            class="flex h-full flex-col items-center justify-between gap-8 text-interface-0"
            :class="getBubbleClass().paddingPosition">
            <div class="flex flex-col">
              <p class="mx-2 mb-10 font-serif text-3.5xl font-normal leading-tighter tracking-tight">
                {{ uiTranslations.addToHomeScreenTitle }}:
              </p>
              <div class="flex flex-col gap-7 *:flex *:items-center *:gap-3">
                <div>
                  <BaseSvg name="hand-pointing-icon" class="w-8 shrink-0 text-ios-blue" />
                  <p>{{ uiTranslations.downloadBenefit1 }}</p>
                </div>

                <div>
                  <BaseSvg name="play-pause-icon" class="w-8 shrink-0 text-ios-blue" />
                  <p>{{ uiTranslations.downloadBenefit2 }}</p>
                </div>

                <div>
                  <BaseSvg name="hand-heart-icon" class="w-8 shrink-0 text-ios-blue" />
                  <p class="leading-5">{{ uiTranslations.downloadBenefit3 }}</p>
                </div>
              </div>

              <button
                v-if="currentCheckin"
                class="mx-2 mt-14 flex items-center gap-1 py-2.5"
                @click="navigateToStartCheckin">
                <p class="text-interface-350 underline">{{ uiTranslations.noThanksContinueInBrowser }}</p>
                <BaseSvg name="arrow-right-icon" class="w-6 text-interface-350" />
              </button>
            </div>
          </section>

          <section
            class="pointer-events-none absolute inset-0 flex h-full w-full flex-col items-end p-6"
            :class="getBubbleClass().bubblePosition">
            <BaseSvg
              v-if="getBubbleClass().isCurlyArrowShown"
              name="curly-arrow-right"
              class="mb-5 mr-8 h-16 w-16 shrink-0 text-ios-blue" />

            <BaseSvg
              v-if="userAgentSpecs.currentDevice === 'iphone' && userAgentSpecs.currentBrowser !== 'chrome'"
              name="curly-arrow-right"
              class="absolute left-1/2 -z-50 -ml-8 mb-8 h-16 rotate-[125deg] text-ios-blue" />
            <div
              @click.self="moveUpRevealArrow"
              :class="[getBubbleClass().arrowPosition, getBubbleClass().flexOrder]"
              class="pointer-events-auto z-50 flex w-full rounded-2xl bg-interface-25 text-black shadow-[0px_-34px_56px_-15px] shadow-black/20 transition-all duration-700 ease-[cubic-bezier(0.68,-0.6,0.32,1.6)]">
              <div class="pointer-events-none flex gap-4 px-6 py-5">
                <img src="/sp_icon_black.png" class="h-20 w-20" />
                <div class="my-1 flex flex-col justify-start">
                  <div class="text-xl font-medium">{{ inject("providerName") }}</div>
                  <div class="text-sm font-medium text-interface-250">{{ inject("providerName") }} A/S</div>
                </div>
              </div>
              <hr class="pointer-events-none border-0 border-b border-b-interface-100" />

              <div class="pointer-events-none flex flex-col items-center justify-center px-6 py-5">
                <div v-show="userAgentSpecs.currentDevice === 'iphone'" class="flex flex-col gap-2 py-1">
                  <div class="flex w-full items-center justify-center gap-1.5">
                    <div class="font-normal">{{ uiTranslations.tapOnThe }}</div>
                    <BaseSvg
                      name="share-icon"
                      class="w-5"
                      :class="userAgentSpecs.currentBrowser === 'chrome' ? 'text-google-grey' : 'text-ios-blue'" />
                    <div class="font-normal">{{ uiTranslations.then }}</div>
                  </div>
                  <div class="flex items-center gap-2 font-semibold">"{{ uiTranslations.addToHomeScreen }}"</div>
                </div>

                <button
                  @click="promptInstall()"
                  v-show="userAgentSpecs.currentDevice === 'android'"
                  :disabled="userAgentSpecs.currentDevice !== 'android'"
                  id="install-button"
                  hidden
                  class="!pointer-events-auto flex w-full cursor-pointer items-center justify-center rounded-lg border-2 border-stone-300 bg-black p-3 text-center text-white">
                  <h3 class="text-lg font-bold">{{ uiTranslations.install }}</h3>
                </button>
              </div>
            </div>
          </section>
        </template>

        <!-- if something is not supported -->
        <section v-else class="flex h-full flex-col items-center justify-between gap-8 text-interface-0">
          <div class="flex flex-col">
            <p class="mx-2 mb-10 font-serif text-3.5xl font-normal leading-tighter tracking-tight">
              {{ uiTranslations.somethingWentWrong }}
            </p>
            <div class="flex flex-col items-start gap-7" v-if="!isBrowserSupported && isOsSupported">
              <div class="flex flex-col items-start gap-8">
                <p>
                  {{ uiTranslations.browserNotSupported }}
                </p>
                <CopyText :textToCopy="getMagicLinkUrl()" />
              </div>
            </div>
            <div class="flex flex-col items-start gap-7" v-if="!isOsSupported">
              <div>
                <p>
                  {{ uiTranslations.iOSVersionNotSupported }}
                </p>
              </div>
            </div>

            <button
              v-if="currentCheckin"
              class="mx-2 mt-14 flex items-center gap-1 py-2.5"
              @click="navigateToStartCheckin">
              <p class="text-interface-350 underline">{{ uiTranslations.noThanksContinueInBrowser }}</p>
              <BaseSvg name="arrow-right-icon" class="w-6 text-interface-350" />
            </button>
          </div>
        </section>
      </div>
    </Transition>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref, inject } from "vue";
import { useRouter, useRoute } from "vue-router";
import { getUserAgentSpecs, checkOnboardingSupport } from "@/helpers/deviceHelpers";
import BaseProgressSteps from "@/components/BaseProgressSteps.vue";
import CopyText from "@/components/CopyText.vue";
import { userStore } from "@/stores/userStore";
import { isiOSWebView } from "@/helpers/deviceHelpers";
import { uiTranslations } from "@/helpers/languageHelpers.ts";
import BaseHeader from "@/components/BaseHeader.vue";
import BaseSvg from "@/components/BaseSvg.vue";
import { currentCheckin } from "@/helpers/checkinHelpers.ts";

const router = useRouter();
const route = useRoute();

const { isBrowserSupported, isOsSupported } = checkOnboardingSupport();
const userAgentSpecs = getUserAgentSpecs();

function navigateToStartCheckin() {
  router.push({ name: "Authenticate", params: { hash: route.params.uid } });
}

function getMagicLinkUrl() {
  const { magicLink } = userStore.user || {};

  if (!magicLink) {
    console.error("Magic link not found on user.");
    return "";
  }

  if (!magicLink.redirectUrl || !magicLink.hash) {
    console.error("Magic link is missing Redirect URL or hash");
    return "";
  }

  return `${magicLink.redirectUrl}/${magicLink.hash}`;
}

function moveUpRevealArrow(event: Event) {
  if (userAgentSpecs.currentDevice !== "iphone") return;
  const eventTarget = event.target as HTMLElement;
  eventTarget?.classList.add("!mb-32");
}

let installPrompt: any = null;
const currentStep = ref(1);

async function promptInstall() {
  if (!installPrompt) {
    console.log("Install prompt not available");
    return;
  }
  await installPrompt.prompt();
}

onMounted(() => {
  window.addEventListener("beforeinstallprompt", (event) => {
    console.log("beforeinstallprompt", event);
    installPrompt = event;
  });
});

function getBubbleClass() {
  const defaultStyles = {
    arrowPosition: "",
    bubblePosition: "justify-end",
    isCurlyArrowShown: false,
    paddingPosition: "pb-64",
    flexOrder: "flex-col",
  };

  if (userAgentSpecs.currentDevice !== "iphone") {
    return defaultStyles;
  }

  if (userAgentSpecs.currentBrowser !== "chrome") {
    return {
      ...defaultStyles,
      arrowPosition: "arrow-bottom-center",
    };
  }

  return {
    ...defaultStyles,
    arrowPosition: "arrow-top-right",
    bubblePosition: "justify-start",
    isCurlyArrowShown: true,
    paddingPosition: "pt-64",
    flexOrder: "flex-col-reverse",
  };
}
</script>

<style scoped>
.arrow-bottom-center,
.arrow-bottom-right {
  margin-bottom: 12px;
}

.arrow-bottom-center,
.arrow-bottom-right,
.arrow-top-right {
  position: relative;
}

.arrow-bottom-center::before,
.arrow-bottom-right::before,
.arrow-top-right::before {
  content: "";
  position: absolute;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
}

.arrow-bottom-center::before {
  top: 100%;
  left: 50%;
  translate: -50%;
  border-top: 12px solid white;
}

.arrow-bottom-right::before {
  top: 100%;
  right: 100px;
  translate: 100%;
  border-top: 12px solid white;
}

.arrow-top-right::before {
  top: -5%;
  right: 100px;
  translate: 100%;
  border-bottom: 12px solid white;
}
</style>

<template>
  <section
    class="flex h-12 w-full gap-2 overflow-clip rounded-lg border-2 border-interface-25 bg-interface-500 pl-2 font-bold text-interface-25">
    <input type="text" :value="props.textToCopy!" id="myInput" class="w-full bg-transparent px-2 font-mono" readonly />
    <button @click="copyTextToClipboard(props.textToCopy!)" class="bg-interface-25 text-interface-1000">
      <div class="h-full w-fit px-4 py-1 text-ios-blue">
        <Transition name="fade-opacity" mode="out-in" :duration="100">
          <BaseSvg v-if="!isCopied" name="clipboard-icon" class="h-full w-6" />
          <BaseSvg v-else name="checkmark-icon" class="h-full w-6" />
        </Transition>
      </div>
    </button>
  </section>
</template>

<script setup lang="ts">
import { ref } from "vue";
import BaseSvg from "@/components/BaseSvg.vue";

const props = defineProps<{
  textToCopy: string;
}>();

const isCopied = ref(false);

function copyTextToClipboard(text: string) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return;
  }

  navigator.clipboard.writeText(text).then(
    () => {
      console.log(`Copying ${text} was successful!`);
      isCopied.value = true;
      setTimeout(() => {
        isCopied.value = false;
      }, 3000);
    },
    (err) => console.error("Async: Could not copy text: ", err),
  );
}

function fallbackCopyTextToClipboard(text: string) {
  const textArea = document.createElement("textarea");
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.position = "fixed";

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    let successful = document.execCommand("copy");
    const msg = successful ? "successful" : "unsuccessful";
    console.log("Fallback: Copying text command was " + msg);

    isCopied.value = true;
    setTimeout(() => {
      isCopied.value = false;
    }, 3000);
  } catch (err) {
    console.error("Fallback: Oops, unable to copy", err);
  }

  document.body.removeChild(textArea);
}
</script>

<template>
  <div class="w-full rounded-full">
    <!-- PROGRESS CIRCLE -->
    <div class="relative w-full overflow-clip" v-if="props.type === 'progress'">
      <!-- path gradient -->
      <div class="absolute h-full w-full rounded-full" :style="{ background: generateDynamicConicGradient() }"></div>

      <svg
        class="mix-blend-multiply"
        width="100%"
        height="100%"
        viewBox="-5 -5 260 260"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        style="transform: rotate(-90deg)">
        <!-- middle hole -->
        <circle
          r="115"
          cx="125"
          cy="125"
          stroke="black"
          stroke-width="30"
          stroke-dasharray="722.2px"
          stroke-dashoffset="0" />
        <!-- path -->
        <circle
          class="rounded-full"
          :class="props.isFillAnimated && props.isFillAnimated ? 'progress-fill-animation' : ''"
          r="110"
          cx="125"
          cy="125"
          stroke="white"
          stroke-width="40"
          stroke-linecap="round"
          fill="transparent"
          stroke-dasharray="722.2px"
          :stroke-dashoffset="generateStrokeDashOffset()" />
      </svg>
      <!-- track -->
      <svg
        class="absolute inset-0 rounded-full"
        width="100%"
        height="100%"
        viewBox="-5 -5 260 260"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg">
        <circle class="stroke-interface-0 opacity-10" r="110" cx="125" cy="125" stroke-width="40" fill="transparent" />
      </svg>
    </div>
    <!-- SPINNER -->
    <div v-else class="blur-xs relative w-full animate-[spin_8s_linear_infinite] overflow-clip rounded-full">
      <div class="absolute h-full w-full rounded-full" style="background: conic-gradient(black 80deg, #7b7a72)"></div>
      <svg
        class="mix-blend-multiply"
        width="100%"
        height="100%"
        viewBox="-5 -5 260 260"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        style="transform: rotate(-20deg)">
        <circle
          r="500"
          cx="125"
          cy="125"
          stroke="black"
          stroke-width="30"
          stroke-dasharray="722.2px"
          stroke-dashoffset="0" />
        <circle
          r="110"
          cx="125"
          cy="125"
          stroke="white"
          stroke-width="40"
          stroke-linecap="round"
          fill="transparent"
          stroke-dasharray="722.2px"
          stroke-dashoffset="200" />
      </svg>
    </div>
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  colors: string[];
  answerCount?: number;
  type: string;
  isFillAnimated?: boolean;
}>();

function generateDynamicConicGradient() {
  const sectionSizeInDegrees = 360 / props.colors.length;
  let gradient = "conic-gradient(from 0deg at center center";
  for (let i = 0; i < props.colors.length; i++) {
    gradient += `, ${props.colors[i]} ${i * sectionSizeInDegrees}deg`;
  }
  const fullCircleDegrees = 360;
  const firstColorForColorBleeding = props.colors[0];
  gradient += `, ${firstColorForColorBleeding} ${fullCircleDegrees}deg`;
  gradient += ")";
  return gradient;
}

function generateStrokeDashOffset() {
  const maxOffset = 722.2;
  const progress = props.answerCount ? (props.answerCount * (100 / props.colors.length)) / 100 : 100;
  const targetOffset = maxOffset - progress * maxOffset;

  return `${targetOffset}px`;
}
</script>

<style scoped>
.progress-fill-animation {
  animation: load-progress 2s;
}
@keyframes load-progress {
  0% {
    stroke-dashoffset: 722.2px;
  }
}
</style>
